<template>
  <div>
    <div class="uk-margin-top">
      <div class="uk-card uk-card-default kandang-card">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Kode Siklus
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formData.cycle_code"
                  v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                  class="uk-input"
                  type="text"
                  name="kode_siklus"
                  placeholder="Masukan kode..."
                  :class="{'uk-form-danger': errors.has('kode_siklus')}"
                >
                <div
                  v-show="errors.has('kode_siklus')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('kode_siklus') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Nama Kandang
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input 
                  v-model="formData.cage"
                  class="uk-input" 
                  name="nama_kandang"
                  disabled 
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Anak Kandang
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formData.cage_staff_name"
                  class="uk-input"
                  type="text"
                  name="cage_child"
                  disabled
                >
              </div>
            </div>
            <!-- <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Merek DOC
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <multiselect 
                  id="chick_supplier" 
                  v-model="merkDocChoose" 
                  v-validate="'required'"
                  label="name"
                  name="merek_doc" 
                  track-by="id" 
                  placeholder="Pilih..." 
                  open-direction="bottom" 
                  :options="dataMerkDocs" 
                  :searchable="true"
                  :loading="isLoading" 
                  :close-on-select="true"
                  :max-height="500"
                  :show-no-results="true"
                  @select="onSelectDocs"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('merek_doc')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('merek_doc') }}
                </div>
              </div>
            </div> -->
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Pengawas Lapangan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formData.field_supervisor_name"
                  class="uk-input"
                  type="text"
                  name="field_supervisor"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Harga Kontrak
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <multiselect 
                  id="chick_supplier" 
                  v-model="priceTypeChoose" 
                  label="name"
                  name="price_type" 
                  track-by="name" 
                  placeholder="Pilih..." 
                  open-direction="bottom" 
                  :options="contractPriceTypeList" 
                  :searchable="true" 
                  :loading="isLoading"
                  :close-on-select="true" 
                  :max-height="500"
                  :show-no-results="true"
                  @select="onSelectDocs"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('merek_doc')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('merek_doc') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Populasi (ekor)
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formData.population"
                  v-validate="'required|numeric'"
                  class="uk-input"
                  type="text"
                  name="populasi"
                  placeholder="Masukan populasi..."
                  :class="{'uk-form-danger': errors.has('populasi')}"
                  disabled
                >
                <div
                  v-show="errors.has('populasi')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('populasi') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Kode Harga
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <div class="uk-width-1 price-field">
                  <input
                    v-model="formData.contract_price_code"
                    class="uk-input"
                    type="text"
                    name="contract_price"
                    disabled
                  >
                  <label
                    :class="[formData.contract_price_code ? 'label-price': 'label-price-muted']"
                    @click="detailPrice"
                  >Lihat</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-side" />
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Mulai Persiapan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="startPrepareDate"
                  v-validate="'required'"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                  name="mulai_persiapan"
                  :disabled-date="disabledPrepareDates"
                  :format="formatDate"
                  :class="{'uk-form-danger': errors.has('mulai_persiapan')}"
                />
                <div
                  v-show="errors.has('mulai_persiapan')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('mulai_persiapan') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Selesai Persiapan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="endPrepareDate"
                  v-validate="'required'"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                  name="mulai_persiapan"
                  :disabled-date="disabledEndPrepareDates"
                  :format="formatDate"
                  :class="{'uk-form-danger': errors.has('mulai_persiapan')}"
                />
                <div
                  v-show="errors.has('mulai_persiapan')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('mulai_persiapan') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Chick In
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="chickInView"
                  :input-class="['uk-input', {'uk-form-danger': errorInputDate.chick_in }]"
                  placeholder="DD-MM-YYYY"
                  name="chick_in"
                  :disabled-date="disabledChickInDates"
                  :format="formatDate"
                  :class="{'uk-form-danger': errors.has('chick_in')}"
                  @change="updatePrice=true"
                />
                <div
                  v-show="errorInputDate.chick_in"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errorInputDate.chick_in }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Hari ke-1
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="startMaintenance"
                  :input-class="['uk-input', {'uk-form-danger': errorInputDate.chick_in }]"
                  placeholder="DD-MM-YYYY"
                  name="chick_in"
                  :disabled-date="disabledDates"
                  :format="formatDate"
                />
                <div
                  v-show="errorInputDate.chick_in"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errorInputDate.chick_in }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Mulai Panen
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="startHarvestDate"
                  :input-class="['uk-input', {'uk-form-danger': errorInputDate.harvest }]"
                  placeholder="DD-MM-YYYY"
                  name="mulai_panen"
                  :disabled-date="disabledHarvestDates"
                  :format="formatDate"
                />
                <div
                  v-show="errorInputDate.harvest"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errorInputDate.harvest }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Chick Out
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="startChickOutDate"
                  :input-class="['uk-input', {'uk-form-danger': errorInputDate.chick_out }]"
                  placeholder="DD-MM-YYYY"
                  name="chick_out"
                  :disabled-date="disabledChickOutDates"
                  :format="formatDate"
                />
                <div
                  v-show="errorInputDate.chick_out"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errorInputDate.chick_out }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Mulai Sanitasi
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="sanitationView"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                  name="chick_out"
                  :disabled-date="disabledSanitationDates"
                  :format="formatDate"
                />
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Selesai Sanitasi
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="endSanitationView"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                  name="chick_out"
                  :disabled-date="disabledEndSanitationDates"
                  :format="formatDate"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-margin-top">
      <SidebarActivity
        :panen-date="panenDate"
        :chick-out-date="chickOutDate"
        :data-delete-message="dataDeleteMessageActivity"
        @dataActivityLogWithDetails="onGetActivityWithDetail"
        @dataDetailLog="onGetDetailLog"
        @dataPemeliharaanLength="onGetLength"
        @dataPanenLength="onGetPanenLength"
        @buttonSaveClick="saveClick"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE, DURATION_ACTIVITY, CONTRACT_PRICE_TYPE_LIST } from '@/utils/constant'
import { dateString, dateAddDays, dateParanjeCompleteString, dateSubstract } from '@/utils/formater'
import SidebarActivity from './sidebar'
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/id'

export default {
  components: {
    SidebarActivity,
    Datepicker
  },
  props: {
    dataActivity: {
      required: true,
      type: Object
    },
    // eslint-disable-next-line vue/require-default-prop
    dataDeleteMessage: {
      required: false,
      type: Object
    },
    contractPrice: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      showProduction: process.env.VUE_APP_ENV == 'production' || process.env.VUE_APP_ENV == 'uat',
      isLoading: false,
      updatePrice: false,
      images: PREFIX_IMAGE,
      formatDate: 'DD MMMM YYYY',
      dataDeleteMessageActivity: {},
      startPrepareDate: '',
      endPrepareDate: '',
      chickInView: '',
      startMaintenance: '',
      startHarvestDate: '',
      startChickOutDate: '',
      checkChickInDate: null,
      checkHarvestDate: null,
      checkChickOutDate: null,
      errorInputDate: {
        chick_in: '',
        harvest: '',
        chick_out: ''
      },
      maintenanceLength: null,
      panenLength: null,
      formData: {
        id: '',
        cycle_code: '',
        population: '',
        chick_supplier_id: '',
        chick_supplier: '',
        cage: '',
        cage_id: '',
        cage_staff: '',
        cage_staff_name: '',
        field_supervisor_id: '',
        field_supervisor_name: '',
        contract_price_id: '',
        contract_price_code: '',
        start_preparation: '',
        end_preparation: '',
        chick_in: '',
        chick_out: '',
        harvest: '',
        sanitation: '',
        edit_detail_activity: false,
        log_activity: []
      },
      cycles: [],
      activities: [],
      showAddActivities: true,
      formActivities: [],
      getToday: new Date(),
      sanitationView: '',
      endSanitationView: '',
      dataActivityWithDetail: [],
      merkDocChoose: {},
      dataMerkDocs: [],
      startPrepareClick: false,
      panenDate: 0,
      chickOutDate: 0,
      cageCategoryDate: null,
      date_detail_activity: false,
      edit_detail_activity: false,
      priceTypeChoose: '',
      contractPriceTypeList: []
    }
  },
  computed: {
    ...mapGetters({
      merkDocs: 'merkDoc/merkDocs',
      newContractPrice: 'contractPrice/chickinContractPrice',
      showAlert: 'activityCage/showAlert'
    })
  },
  watch: {
    contractPrice(){
      const checkContractPrice =  this.contractPrice.price_type == 'Rp0' ? 'Normal' : this.contractPrice.price_type
      this.priceTypeChoose = this.contractPriceTypeList.filter(item => item.name == checkContractPrice )
    },
    async priceTypeChoose(){
      if(this.formData.chick_in){
        if(Number(this.priceTypeChoose.value)){
          await this.getNewContractPrice(
            {
              effective_date: dateString(this.formData.chick_in),
              price_type: Number(this.priceTypeChoose.value)
            }
          )
          if(this.newContractPrice){
            console.log(this.newContractPrice)
            this.contractPriceByChickin = this.newContractPrice
            this.formData.contract_price_id = this.contractPriceByChickin.id
            this.formData.contract_price_code = this.contractPriceByChickin.code
            this.updatePrice = true
          }
        }
      }
    },
    async dataActivity() {
      this.cageCategoryDate = this.dataActivity.data.chick_capacity
      if (this.dataActivity.data) {
        this.formData = {
          cycle_code: this.dataActivity.data.cycle_code,
          cage: this.dataActivity.data.cage,
          name: this.dataActivity.data.name,
          cage_id: this.dataActivity.data.cage_id,
          cage_staff_name: this.dataActivity.data.cage_staff,
          cage_staff: this.dataActivity.data.cage_staff_id,
          field_supervisor_name: this.dataActivity.data.field_supervisor,
          field_supervisor_id: this.dataActivity.data.field_supervisor_id,
          contract_price_code: this.dataActivity.data.contract_price_code,
          contract_price_id: this.dataActivity.data.contract_price_id,
          city: this.dataActivity.data.city,
          count_cycle: this.dataActivity.data.count_cycle,
          population: this.dataActivity.data.population,
          chick_supplier_id: this.dataActivity.data.chick_supplier_id,
          chick_supplier: this.dataActivity.data.chick_supplier != '' ? this.dataActivity.data.chick_supplier : this.merkDocChoose.name,
          start_preparation : dateParanjeCompleteString(this.dataActivity.data.start_preparation),
          end_preparation : dateParanjeCompleteString(this.dataActivity.data.end_preparation),
          chick_in : dateParanjeCompleteString(this.dataActivity.data.chick_in),
          start_maintenance : dateParanjeCompleteString(this.dataActivity.data.start_maintenance),
          harvest : dateParanjeCompleteString(this.dataActivity.data.start_harvest),
          chick_out : dateParanjeCompleteString(this.dataActivity.data.chick_out),
          sanitation : dateParanjeCompleteString(this.dataActivity.data.sanitation)
        }
        this.startPrepareDate = new Date(this.dataActivity.data.start_preparation)
        this.endPrepareDate = new Date(this.dataActivity.data.end_preparation)
        this.chickInView = new Date(this.dataActivity.data.chick_in)
        this.startMaintenance = new Date(this.dataActivity.data.start_maintenance)
        this.startHarvestDate = new Date(this.dataActivity.data.start_harvest)
        this.startChickOutDate = new Date(this.dataActivity.data.chick_out)
        this.sanitationView = new Date(this.dataActivity.data.sanitation)
        this.endSanitationView = new Date(this.dataActivity.data.end_sanitation)
        
        await this.getMerkDoc({['by_item_category']: 'doc',is_all: true})
      }
    },
    merkDocs() {
      let theDocs =  this.merkDocs.data
      for(let i = 0; i<= theDocs.length; i++) {
        if(theDocs[i].id == this.dataActivity.data.chick_supplier_id) {
          this.merkDocChoose = { id: this.dataActivity.data.chick_supplier_id, name: theDocs[i].name}
          i = theDocs.length
        }
      }
    },
    async dataDeleteMessage () {
      this.dataDeleteMessageActivity = this.dataDeleteMessage
    },
    startPrepareDate() {
      this.formData.start_preparation = dateAddDays(this.startPrepareDate, 0)
      const tommorowDay = DURATION_ACTIVITY.START_PREPARATION // PLUS 6 DAY
      const addDays = dateAddDays(this.startPrepareDate, tommorowDay)
      const dateEndPreparation = dateParanjeCompleteString(addDays)
      dateEndPreparation == 'Invalid Date' ? '' : (this.formData.end_preparation = dateEndPreparation)
      if (this.startPrepareClick === true) {
        this.endPrepareDate = addDays ? new Date(addDays) : ''
        this.date_detail_activity = true
      }
    },
    endPrepareDate() {
      this.formData.end_preparation = dateAddDays(this.endPrepareDate, 0)
      const tommorowDay = DURATION_ACTIVITY.END_PREPARATION // PLUS 1 DAY
      const addDays = dateAddDays(this.endPrepareDate, tommorowDay)
      const dateChickIn = dateParanjeCompleteString(addDays)
      dateChickIn == 'Invalid Date' ? '' : (this.formData.chick_in = dateChickIn)
      if (this.startPrepareClick === true) {
        this.chickInView = addDays ? new Date(addDays) : ''
        this.date_detail_activity = true
      }
    },
    async chickInView() {
      this.formData.chick_in = dateAddDays(this.chickInView, 0)
      const tommorowDay = DURATION_ACTIVITY.CHICK_IN // PLUS 1 DAY
      const addDays = dateAddDays(this.chickInView, tommorowDay)
      const maintenanceDate = dateParanjeCompleteString(addDays)
      const tommorowHarvest = DURATION_ACTIVITY.CHICK_IN_HARVEST // PLUS 36 DAY
      const addDaysHarvest = dateAddDays(this.chickInView, tommorowHarvest)
      const dateHarvest = dateParanjeCompleteString(addDaysHarvest)
      maintenanceDate == 'Invalid Date' ? '' : (this.formData.start_maintenance = maintenanceDate)
      dateHarvest == 'Invalid Date' ? '' : (this.formData.harvest = dateHarvest)
      if (this.startPrepareClick === true) {
        this.startMaintenance = addDays ? new Date(addDays) : ''
        this.startHarvestDate = addDaysHarvest ? new Date(addDaysHarvest) : ''
        this.date_detail_activity = true
      }
      //for contract price
      if(this.formData.chick_in){
        if(this.priceTypeChoose){
          await this.getNewContractPrice(
            {
              effective_date: dateString(this.formData.chick_in),
              price_type: Number(this.priceTypeChoose.value)
            }
          )
        }
      }
      this.contractPriceByChickin = this.newContractPrice
      this.formData.contract_price_id = this.contractPriceByChickin.id
      this.formData.contract_price_code = this.contractPriceByChickin.code
      this.updatePrice = true
    },
    startMaintenance() {
      this.formData.start_maintenance = dateAddDays(this.startMaintenance, 0)
      const diffHarvestDate = (this.startHarvestDate - this.startMaintenance)
      this.panenDate = diffHarvestDate/(1000 * 60 * 60 * 24)
      const tommorowDay = DURATION_ACTIVITY.CHICK_IN_HARVEST
      const addDays = dateAddDays(this.startMaintenance, tommorowDay)
      const startHarvest = new Date(addDays)
      startHarvest == 'Invalid Date' ? '' : (this.formData.harvest = startHarvest)
      if (this.startPrepareClick === true) {
        this.startHarvestDate = addDays &&  new Date(startHarvest) !=  'Invalid Date' ? startHarvest : new Date(startHarvest)
        this.date_detail_activity = true
      }
    },
    startHarvestDate() {
      this.formData.harvest = dateAddDays(this.startHarvestDate, 0)
      const diffHarvestDate = (this.startHarvestDate - this.startMaintenance)
      this.panenDate = diffHarvestDate/(1000 * 60 * 60 * 24)
      const diffChickOutDate = (this.startChickOutDate - this.startHarvestDate)
      this.chickOutDate = (diffChickOutDate/(1000 * 60 * 60 * 24)) + 1
      const tommorowDay = DURATION_ACTIVITY.HARVEST // PLUS 2 DAY
      const addDays = dateAddDays(new Date(this.startHarvestDate), tommorowDay)
      const harvestDate = dateParanjeCompleteString(addDays.$d)
      harvestDate == 'Invalid Date' ? '' : (this.formData.chick_out = harvestDate)
      if (this.startPrepareClick === true) {
        this.startChickOutDate = addDays ? new Date(addDays) : ''
        this.date_detail_activity = true
      }
    },
    startChickOutDate() {
      this.formData.chick_out = dateAddDays(this.startChickOutDate, 0)
      const diffChickOutDate = (this.startChickOutDate - this.startHarvestDate)
      this.chickOutDate = (diffChickOutDate/(1000 * 60 * 60 * 24)) + 1
      const tommorowDay = DURATION_ACTIVITY.CHICK_OUT // PLUS 1 DAY
      const tommorow = dateAddDays(this.startChickOutDate, tommorowDay)
      const formatDate = dateParanjeCompleteString(tommorow)
      formatDate == 'Invalid Date' ? '' : (this.formData.sanitation = formatDate)
      if (this.startPrepareClick === true) {
        this.sanitationView = tommorow ? new Date(tommorow) : ''
        this.date_detail_activity = true
      }
    },
    sanitationView() {
      this.formData.sanitation = dateAddDays(this.sanitationView, 0)
      const tommorowDay = DURATION_ACTIVITY.SANITATION /* PLUS 7 DAY */
      const addDays = dateAddDays(this.sanitationView, tommorowDay)
      const formatDate = dateParanjeCompleteString(addDays)
      formatDate == 'Invalid Date' ? '' : (this.formData.end_sanitation = formatDate)
      if (this.startPrepareClick === true) {
        this.endSanitationView = addDays ? new Date(addDays) : ''
        this.date_detail_activity = true
      }
    },
    endSanitationView() {
      if (this.startPrepareClick === true) {
        this.date_detail_activity = true
      }
      this.distinctLogDetail()
    }
  },
  async mounted() {
    await this.getMerkDoc({['by_item_category']: 'doc',is_all: true})

    this.dataMerkDocs = await this.merkDocs.data ? this.merkDocs.data : []

    this.contractPriceTypeList = CONTRACT_PRICE_TYPE_LIST.filter(item => item.name !== 'Semua').map(item => {
      if (item.name === 'Rp.0') {
        return { name: 'Normal', value: item.value }
      }
      return item
    })
  },
  methods: {
    checkedChickInDate() {
      const checkChickInDate = this.dataActivityWithDetail.find(x => x.work_date_start === dateString(this.chickInView))
      this.checkChickInDate = checkChickInDate
    },
    checkedHarvestDate() {
      const checkHarvestDate = this.dataActivityWithDetail.find(x => x.work_date_start === dateString(this.startHarvestDate))
      this.checkHarvestDate = checkHarvestDate
    },
    checkedChickOutDate() {
      const checkChickOutDate = this.dataActivityWithDetail.find(x => x.work_date_start === dateString(this.startChickOutDate))
      this.checkChickOutDate = checkChickOutDate
    },
    onGetLength (length) {
      this.maintenanceLength = length
    },
    onGetPanenLength (length) {
      this.panenLength = length
    },
    saveClick (value) {
      if(value !== undefined) {
        if(value === true || this.date_detail_activity === true) {
          this.edit_detail_activity = true
        } else {
          this.edit_detail_activity = false
        }
        this.showSaveConfirmModal()
      }
    },
    async onGetActivityWithDetail (temp) {
      this.dataActivityWithDetail = temp
      this.checkedChickInDate()
      this.checkedHarvestDate()
      this.checkedChickOutDate()
    },
    onGetDetailLog (detail) {
      this.formActivities = detail
    },
    distinctLogDetail() {
      // PARENT DATA ASSIGN
      this.formData.id = this.$route.params.id
      this.formData.population = String(this.formData.population)
      this.formData.start_preparation = dateString(this.startPrepareDate)
      this.formData.end_preparation = dateString(this.endPrepareDate)
      this.formData.chick_in = dateString(this.chickInView)
      this.formData.start_maintenance = dateString(this.startMaintenance)
      this.formData.harvest = dateString(this.startHarvestDate)
      this.formData.chick_out = dateString(this.startChickOutDate)
      this.formData.sanitation = dateString(this.sanitationView)
      this.formData.end_sanitation = dateString(this.endSanitationView)

      this.formActivities.forEach(el => {
        const result = this.dataActivityWithDetail.find(x => x.name === el.flag)
        if (result) {
          let dateAssign = ''
          let dateAssignEnd = ''
          let asignChild = []

          if (result.name && result.name === 'PERSIAPAN KANDANG') {
            dateAssign = this.formData.start_preparation
            dateAssignEnd = this.formData.end_preparation

            result.work_date_start = this.formData.start_preparation
            result.work_date_end = this.formData.end_preparation
          } else if (result.name && result.name === 'CHICK IN') {
            dateAssign = this.formData.chick_in

            result.work_date_start = this.formData.chick_in
            result.work_date_end = this.formData.chick_in
          } else if (result.name && result.name === 'SANITASI') {
            dateAssign = this.formData.sanitation

            result.work_date_start = this.formData.sanitation
            result.work_date_end = this.formData.end_sanitation
          } else if (result.name && result.name.includes('PANEN HARI KE')) {
            dateAssign = this.getHarvestDateDayActivity(result.name)
            result.work_date_start = dateAssign
            result.work_date_end = dateAssign
          } else if (result.name && result.name.includes('HARI KE')) {
            // DIFF DAYS FOR ASSIGN DATE
            // const diffDaysDateHarvest = dateDiffDays(this.formData.harvest, this.formData.chick_in)
            // const diffDaysDateChickOut = dateDiffDays(this.formData.chick_out, this.formData.chick_in)

            // if (result.name === `HARI KE ${diffDaysDateHarvest}`) {
            //   dateAssign = this.formData.harvest
            //   result.work_date_start = this.formData.harvest
            //   result.work_date_end = dateString(dateSubstract(this.formData.chick_out, 1, 'day'))
            // } else if (result.name === `HARI KE ${diffDaysDateChickOut}`) {
            //   dateAssign = this.formData.chick_out
            //   result.work_date_start = this.formData.chick_out
            //   result.work_date_end = this.formData.chick_out
            // } else {
            //   dateAssign = this.getDateDayActivity(result.name)
            //   result.work_date_start = dateAssign
            //   result.work_date_end = dateAssign
            // }
            dateAssign = this.getDateDayActivity(result.name)
            result.work_date_start = dateAssign
            result.work_date_end = dateAssign
          } else { // if custom tahapan
            this.setShowAlert({ is_show: true, name: (result.name || '-'), type: 0 })
          }

          if (result.name && result.name === 'PERSIAPAN KANDANG') {
            const dataAsignChild = {
              id: el.id,
              name: el.name,
              activity_category_id: el.activity_category_id,
              detail_activity_category_id: el.activity_category_id,
              sub_activity_category_id: el.sub_activity_category_id,
              activity_id: el.activity_id.activity_id,
              input_type_id: el.input_type_id,
              description: el.description,
              time_start: `${dateAssign} ${el.time_start.slice(el.time_start.length - 5)}`,
              time_end: `${dateAssignEnd} ${el.time_end.slice(el.time_end.length - 5)}`
            }
            asignChild = dataAsignChild
          } else {
            const dataAsignChild = {
              id: el.id,
              name: el.name,
              activity_category_id: el.activity_category_id,
              detail_activity_category_id: el.activity_category_id,
              sub_activity_category_id: el.sub_activity_category_id,
              activity_id: el.activity_id.activity_id,
              input_type_id: el.input_type_id,
              description: el.description,
              time_start: `${dateAssign} ${el.time_start.slice(el.time_start.length - 5)}`,
              time_end: `${dateAssign} ${el.time_end.slice(el.time_end.length - 5)}`
            }
            asignChild = dataAsignChild
          }
          const indexDetail = result.log_detail_activity.findIndex(x => x.id === el.id)
          const notFound = -1
          if (el.id && indexDetail !== notFound) { // prevent duplicate
            result.log_detail_activity.splice(indexDetail, 1, asignChild)
          } else {
            result.log_detail_activity.push(asignChild)
          }
        } /* else { // if any data detail activity category detail don't in list activity
          this.setShowAlert({ is_show: true, name: (el.name || '-'), type: 1 })
        } */
      })
      // PARENT DATA ASSIGN DETAIL
      this.formData.log_activity = this.dataActivityWithDetail
    },
    getDateDayActivity(dayName) {
      const splitDay = dayName.split('HARI KE ')
      const addDays = parseInt(splitDay[1])
      const dateDayActive = dateSubstract(this.startMaintenance, 1, 'day')
      const dayResult = dateAddDays(dateDayActive, addDays)
      return dateString(dayResult)
    },
    getHarvestDateDayActivity(dayName) {
      const splitDay = dayName.split('PANEN HARI KE ')
      const addDays = parseInt(splitDay[1])
      const dateDayActive = dateSubstract(this.startHarvestDate, 1, 'day')
      const dayResult = dateAddDays(dateDayActive, addDays)
      return dateString(dayResult)
    },
    ...mapActions({
      getNewContractPrice: 'contractPrice/getContractPriceByChickin',
      getMerkDoc: 'merkDoc/getMerkDoc',
      getActivity: 'activity/getActivity',
      getMasterActivityDetail: 'masterActivityDetail/getMasterActivityDetail'
    }),
    ...mapMutations({
      setModalEdit: 'activityCage/SET_MODAL_EDIT',
      setModalPrice: 'contractPrice/SET_MODAL_PRICE',
      setShowAlert: 'activityCage/SET_SHOW_ALERT'
    }),
    onSelectDocs(val) {
      this.formData.chick_supplier_id = val.id
      this.formData.chick_supplier = val.name
    },
    dateFormatFilter(date) {
      return dateString(date)
    },
    async detailPrice() {
      if(this.formData.contract_price_id !== '' || this.formData.contract_price_code !== '') {
        if(this.updatePrice === true){
          await this.setModalPrice({ data: this.contractPriceByChickin, date: this.contractPriceByChickin.contract_price_chicken[0].updated_at })
          window.UIkit.modal('#modal-contract-price').show()
        } else {
          await this.setModalPrice({ data: this.contractPrice, date: this.contractPrice.contract_price_chicken[0].updated_at })
          window.UIkit.modal('#modal-contract-price').show()
        }
      }
    },
    disabledPrepareDates(date) {
      this.dateClick()
      return date < new Date(Date.now() - 8640000)
    },
    disabledEndPrepareDates(date) {
      this.dateClick()
      return (
        this.formData.start_preparation < (Date.now() - 8640000) ? (
          date < new Date(Date.now() - 8640000)
        ) : (
          date < new Date(this.formData.start_preparation)
        )
      )
    },
    disabledChickInDates(date) {
      this.dateClick()
      return (
        this.formData.end_preparation < (Date.now() - 8640000) ? (
          date < new Date(Date.now() - 8640000)
        ) : (
          date < new Date(dateAddDays(this.formData.chick_in, 0)) || date > new Date(dateAddDays(this.formData.chick_in, 0))
        )
      )
    },
    disabledDates(date) {
      this.dateClick()
      return (
        this.formData.chick_in < (Date.now() - 8640000) ? (
          date < new Date(Date.now() - 8640000)
        ) : (
          date < new Date(dateAddDays(this.formData.chick_in, 0)) || date > new Date(dateAddDays(this.formData.chick_in, DURATION_ACTIVITY.CHICK_IN))
        )
      )
    },
    disabledHarvestDates(date) {
      this.dateClick()
      return (
        this.formData.start_maintenance < (Date.now() - 8640000) ? (
          date < new Date(Date.now() - 8640000)
        ) : (
          this.maintenanceLength ? (
            date < new Date(this.formData.start_maintenance) || date > new Date(dateAddDays(this.formData.start_maintenance, (this.maintenanceLength)))
          ) : (
            date < new Date(this.formData.start_maintenance)
          )
        )
      )
    },
    disabledChickOutDates(date) {
      this.dateClick()
      return (
        this.formData.harvest < (Date.now() - 8640000) ? (
          date < new Date(Date.now() - 8640000)
        ) : date < new Date(dateSubstract(this.formData.harvest, 0, 'day')) || date > new Date(dateAddDays(this.formData.harvest, 2))
        // (
        //   this.panenLength ? (
        //     date < new Date(dateSubstract(this.formData.harvest, 0, 'day')) || date > new Date(dateAddDays(this.formData.harvest, (this.panenLength - 1)))
        //   ) : (
        //     date < new Date(dateSubstract(this.formData.harvest, 0, 'day'))
        //   )
        // )
      )
    },
    disabledSanitationDates(date) {
      this.dateClick()
      return (
        this.formData.chick_out < (Date.now() - 8640000) ? (
          date < new Date(Date.now() - 8640000)
        ) : (
          date < new Date(this.formData.chick_out) || date > new Date(dateAddDays(this.formData.chick_out, DURATION_ACTIVITY.CHICK_OUT))
        )
      )
    },
    disabledEndSanitationDates(date) {
      return (
        this.formData.sanitation < (Date.now() - 8640000) ? (
          date < new Date(Date.now() - 8640000)
        ) : (
          date <= new Date(dateSubstract(this.formData.sanitation, 1, 'day')) || date > new Date(dateAddDays(this.formData.sanitation, DURATION_ACTIVITY.SANITATION))
        )
      )
    },
    dateClick() {
      this.startPrepareClick = true
    },
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    async showSaveConfirmModal() {
      this.setShowAlert({ is_show: false, name: '-', type: 0 })
      let checkChickInDate = this.dataActivityWithDetail.find(x => x.work_date_start === dateString(this.chickInView))
      let checkHarvestDate = this.dataActivityWithDetail.find(x => x.work_date_start === dateString(this.startHarvestDate))
      let checkChickOutDate = this.dataActivityWithDetail.find(x => x.work_date_start === dateString(this.startChickOutDate))
      let checkedDate = true
      // FOR ALERT DATE EDIT
      let checkDateEdit = false
      if (this.dataActivity.data.start_harvest !== dateString(this.startHarvestDate)) {
        checkDateEdit = true
      }
      if (this.dataActivity.data.chick_out !== dateString(this.startChickOutDate)) {
        checkDateEdit = true
      }
      if (!checkChickInDate) {
        checkChickInDate = {
          expire: false,
          name: ''
        }
      }
      if (!checkHarvestDate) {
        checkHarvestDate = {
          expire: false,
          name: ''
        }
      }
      if (!checkChickOutDate) {
        checkChickOutDate = {
          expire: false,
          name: ''
        }
      }
      if (checkChickInDate.expire && checkChickInDate.name !== this.checkChickInDate.name) {
        this.errorInputDate.chick_in = `tanggal yang dipilih sudah melakukan kegiatan ${checkChickInDate.name}`
        checkedDate = false
      } 
      if (checkHarvestDate.expire && checkHarvestDate.name !== this.checkHarvestDate.name) {
        this.errorInputDate.harvest = `tanggal yang dipilih sudah melakukan kegiatan ${checkHarvestDate.name}`
        checkedDate = false
      }
      if (checkChickOutDate.expire && checkChickOutDate.name !== this.checkChickOutDate.name) {
        this.errorInputDate.chick_out = `tanggal yang dipilih sudah melakukan kegiatan ${checkChickOutDate.name}`
        checkedDate = false
      }
      if (checkedDate) {
        this.errorInputDate = {}
        this.distinctLogDetail()
        this.formData.contract_price_id = this.updatePrice ? this.contractPriceByChickin.id : this.newContractPrice.data[0].id
        this.formData.contract_price_code = this.updatePrice ? this.contractPriceByChickin.code : this.newContractPrice.data[0].id
        this.formData.edit_detail_activity = this.edit_detail_activity

        const showPopup = this.dataActivityWithDetail.find(x => x.log_detail_activity.length === 0)
        if (showPopup) { // if data activity don't have detail
          this.setShowAlert({ is_show: true, name: (showPopup.name || '-'), type: 2 })
        }

        this.$validator.validateAll().then((success) => {
          if (success) {
            if (this.$validator.errors.any()) return

            if (this.showAlert.is_show) {
              window.UIkit.modal('#modal-show-alert-edit-activity').show()
            } else {
              this.setModalEdit(this.formData)
              if (checkDateEdit) {
                window.UIkit.modal('#modal-date-confirm').show()
              } else {
                window.UIkit.modal('#modal-save-confirm').show()
              }
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.bottom-side {
  border-top: 1px solid #e0ebe6;
  padding-top: 32px !important;
  margin-top: 32px !important;
}
.left-repeat {
  padding-left: 0px;
}
.price-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #F0F5F3;
  background-color: #F0F5F3;
}
.label-price {
  cursor: pointer;
  margin: 0 12px;
  font-size: 14px;
  color: #049559;
}
.label-price-muted {
  cursor: pointer;
  margin: 0 12px;
  font-size: 14px;
  color: #777;
}
</style>
